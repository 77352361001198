<template>
  <picture
    ref="elRef"
    :class="[
      layout === 'fill'
        ? 'absolute left-0 top-0 block size-full'
        : 'relative block',
      isBg && 'pointer-events-none',
    ]"
    :style="elStyle"
  >
    <img
      :src="mediaSrc"
      :srcset="mediaSrc === src ? srcset : undefined"
      :sizes="sizes"
      :class="[
        'absolute left-0 top-0 block size-full',
        contain ? 'object-contain' : 'object-cover',
        !transitionOff && 'transition-opacity',
        isVisible ? 'opacity-100' : 'opacity-0',
        mediaClass,
      ]"
      :style="mediaStyle"
      :alt="alt"
    />
    <slot :visible="isVisible" :error="isError" />
  </picture>
</template>

<script lang="ts" setup>
import { computed, ref, type StyleValue } from 'vue';
import { useMediaIntersectionObserver } from '~/components/Media/useMediaIntersectionObserver';

const EMPTY_SRC =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

interface Props {
  src: string;
  height: number;
  width: number;
  alt?: string;
  contain?: boolean;
  layout?: 'responsive' | 'fill' | 'static';
  mediaClass?: string | object | [];
  mediaStyle?: StyleValue;
  srcset?: string;
  sizes?: string;
  transitionOff?: boolean;
  isBg?: boolean;
  placeholder?: string;
}

const props = withDefaults(defineProps<Props>(), {
  alt: undefined,
  layout: 'responsive',
  mediaClass: undefined,
  mediaStyle: undefined,
  srcset: undefined,
  sizes: undefined,
  placeholder: undefined,
});

const elRef = ref<HTMLDivElement | null>(null);

const isVisible = ref(!!props.placeholder);
const isError = ref(false);
const mediaSrc = ref(props.placeholder || EMPTY_SRC);

const paddingBottom = computed(
  () => ((Number(props.height) / Number(props.width)) * 100).toFixed(2) + '%',
);

const elStyle = computed(() => {
  const style: StyleValue = {};

  if (props.layout === 'responsive') {
    style.paddingBottom = paddingBottom.value;
  } else if (props.layout === 'static') {
    style.width = `${props.width}px`;
    style.height = `${props.height}px`;
  }

  return style;
});

function preloadMedia() {
  if (props.src === mediaSrc.value) {
    return;
  }

  isError.value = false;
  isVisible.value = !!props.placeholder;

  const img = new Image();

  img.onload = function () {
    mediaSrc.value = props.src;
    isVisible.value = true;
  };
  img.onerror = function () {
    isError.value = true;
    mediaSrc.value = EMPTY_SRC;
  };
  img.src = props.src;
}

useMediaIntersectionObserver({
  elRef,
  cacheId: () => props.src,
  callback: (entry, unobserve) => {
    if (entry.isIntersecting) {
      preloadMedia();
      unobserve();
    }
  },
});
</script>
